import React, { useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import IntelCoverageNoteForm from './IntelCoverageNoteForm'

type IntelCoverageNoteModalProps = {
    initVal: DTO.IntelCoverageNote | null
    setInitVal: (initVal: DTO.IntelCoverageNote | null) => void
    playerName: string
    isManager: boolean
    authorName: string
}

const IntelCoverageNoteModal = ({
    initVal,
    setInitVal,
    playerName,
    isManager,
    authorName,
}: IntelCoverageNoteModalProps): JSX.Element => {
    const onClose = useCallback(() => setInitVal(null), [setInitVal])
    return (
        <Dialog open={!!initVal} onClose={onClose} fullWidth>
            <DialogTitle sx={{ paddingBottom: '2px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <Box> {playerName} - Intel Coverage </Box>
                    {initVal?.note && authorName && (
                        <Typography sx={{ fontSize: '16px', color: 'gray' }}> Author: {authorName}</Typography>
                    )}
                </Box>

                {!!initVal?.isResolved && (
                    <Typography sx={{ fontSize: '16px', color: 'gray', fontStyle: 'italic', marginBottom: 0 }}>
                        Resolved
                    </Typography>
                )}
            </DialogTitle>
            {!!initVal && <IntelCoverageNoteForm onClose={onClose} initVal={initVal} isManager={isManager} />}
        </Dialog>
    )
}

export default IntelCoverageNoteModal
