import React from 'react'
import * as yup from 'yup'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useFormik } from 'formik'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useToastContext from '../../lib/hooks/useToastContext'
import RichText from '../form/RichText/Editor'
import { useSaveIntelCoverageNote } from '@/lib/hooks'

const validationSchema = yup.object().shape({
    note: yup.string().required(),
})

export type IntelCoverageNoteFormProps = {
    onClose: () => void
    initVal: DTO.IntelCoverageNote
    isManager: boolean
}

const IntelCoverageNoteForm = ({ onClose, initVal, isManager }: IntelCoverageNoteFormProps): JSX.Element => {
    const { mutate: submit } = useSaveIntelCoverageNote()
    const toastContext = useToastContext()

    const formik = useFormik<DTO.IntelCoverageNote>({
        validationSchema,
        initialValues: initVal,
        onSubmit: (val) => {
            if (val.season && val.playerId && val.intelCoverageNoteId) {
                const submitVal: DTO.IntelCoverageNote = {
                    season: val.season,
                    playerId: val.playerId,
                    note: val.note || null,
                    isResolved: val.isResolved,
                    intelCoverageNoteId: val.intelCoverageNoteId,
                }

                submit(submitVal, {
                    onSuccess: () => {
                        toastContext?.addToast({
                            severity: 'success',
                            message: 'Note Saved',
                        })
                        formik.resetForm()
                        onClose()
                    },
                    onError: () => {
                        toastContext?.addToast({
                            severity: 'error',
                            message: 'Note Failed To Save',
                        })
                        formik.resetForm()
                        onClose()
                    },
                })
            }
        },
    })

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {isManager ? (
                            <RichText
                                onChange={(e) => void formik.setFieldValue('note', e)}
                                initialValue={formik.values.note || undefined}
                                label="Note"
                                id="note"
                                minHeight={100}
                                maxHeight={100}
                                placeholder="Enter Note..."
                            />
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    color: 'text.primary',
                                }}
                                dangerouslySetInnerHTML={{ __html: formik.values.note || '' }}
                            />
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', paddingX: 1 }}>
                    {initVal.note && isManager && (
                        <Box sx={{ width: '50%' }}>
                            <LoadingButton
                                type="submit"
                                variant="outlined"
                                loading={formik.isSubmitting}
                                disabled={formik.isSubmitting || !!initVal.isResolved}
                                onClick={() => {
                                    void formik.setFieldValue('isResolved', true)
                                }}
                            >
                                Mark as Resolved
                            </LoadingButton>
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: 1 }}>
                        <Button onClick={onClose} sx={{ color: 'text.secondary' }}>
                            Cancel
                        </Button>
                        {isManager ? (
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={formik.isSubmitting}
                                onClick={() => {
                                    void formik.setFieldValue('isResolved', false)
                                }}
                                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                            >
                                Submit
                            </LoadingButton>
                        ) : (
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={formik.isSubmitting}
                                disabled={formik.isSubmitting || !!initVal.isResolved}
                                onClick={() => {
                                    void formik.setFieldValue('isResolved', true)
                                }}
                            >
                                Mark as Resolved
                            </LoadingButton>
                        )}
                    </Box>
                </Box>
            </DialogActions>
        </form>
    )
}

export default IntelCoverageNoteForm
