import IconButton from '@mui/material/IconButton'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'

const IntelCoverageNoteIconButton = ({
    playerId,
    season,
    note,
    setSelectedNote,
}: {
    playerId: string
    season: number
    note: DTO.IntelCoverageNote | null
    setSelectedNote: (note: DTO.IntelCoverageNote) => void | null
}): JSX.Element => (
    <IconButton
        className="edit-icon"
        sx={{
            padding: '2px',
            marginLeft: '2px',
            fontSize: '12px',
            display: note ? 'flex' : 'none',
        }}
        onClick={() =>
            setSelectedNote(
                note || {
                    playerId,
                    season,
                    intelCoverageNoteId: uuidv4(),
                    note: null,
                    isResolved: false,
                }
            )
        }
    >
        {note ? (
            <TextSnippetIcon
                sx={{
                    color: note.isResolved ? 'gray' : 'orange',
                    strokeWidth: 1,
                    fontSize: '18px',
                    stroke: 'white',
                }}
            />
        ) : (
            <NoteAddIcon
                sx={{
                    color: 'orange',
                    strokeWidth: 1,
                    fontSize: '18px',
                    stroke: 'white',
                }}
            />
        )}
    </IconButton>
)

export default IntelCoverageNoteIconButton
