import type { ScoutAssignmentQueryParams } from '../../lib/hooks/useScoutAssignments'
import type { BoardPlayerWithBoxScore } from './postEventTableConfig'
import React, { useState, useEffect, useMemo } from 'react'
import * as yup from 'yup'
import { useSession } from 'next-auth/react'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useFormik } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { v4 as uuidv4 } from 'uuid'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import GroupsIcon from '@mui/icons-material/Groups'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import Divider from '@mui/material/Divider'
import { Skeleton } from '@mui/material'
import handleError from '../../lib/utils/handleError'
import {
    useAddAssignment,
    useQueryBoardPlayers,
    useEditScouts,
    useGameBoxScores,
    useQueryIncompleteReports,
    useBreakPoints,
    useQueryMappedAssignmentsForCalendar,
} from '../../lib/hooks'
import useToastContext from '../../lib/hooks/useToastContext'
import Table from '../Table'
import ImageWithFallback from '../ImageWithFallback'
import { formatDateString, isSameOrAfterToday } from '../../lib/utils/formatters'
import Link from '../Link'
import SelectScout from '../form/SelectScout'
import SelectBoard from '../form/SelectBoard'
import GameDetailsHeader from '../games/GameDetailsHeader'
import TeamTableTitle from '../games/TeamTableTitle'
import LOCDialog from '../dialog/LOCDialog'
import SkillDialog from '../dialog/SkillDialog'
import IntelCoverageNoteModal from '../coverage/IntelCoverageNoteModal'
import preEventTableConfig from './preEventTableConfig'
import postEventTableConfig from './postEventTableConfig'
import RequiredReportModal from './RequiredReportModal'
import SelectLevel from './SelectLevel'
import { getIncompleteAssignmentsQueryParams } from '@/lib/hooks/useUserFollowing'
import { useConstantsContext } from '@/lib/contexts/ConstantsContext'

type DialogHeaderProps = {
    isGame: boolean
    isCamp: boolean
    event: DTO.AssignmentCalendarExtendedProps | undefined
    isLandscapeOrDesktop: boolean
    isFutureEvent: boolean
    isLoading: boolean
    eventDate: string | undefined
}

const ScoutingEventName = ({ scoutingEventName }: { scoutingEventName: string }) => (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Typography variant="h6">{scoutingEventName}</Typography>
    </Box>
)

const SingleTeamHeader = ({
    event,
    isLandscapeOrDesktop,
    isLoading,
}: {
    event: DTO.AssignmentCalendarExtendedProps | undefined
    isLandscapeOrDesktop: boolean
    isLoading: boolean
}) => (
    <>
        {event?.team?.teamLogoUrl && (
            <Box
                sx={{
                    position: 'relative',
                    height: 36,
                    minWidth: 36,
                }}
            >
                <ImageWithFallback src={event.team.teamLogoUrl} alt="team logo" fallbackSize={{ sm: 36 }} priority>
                    <GroupsIcon />
                </ImageWithFallback>
            </Box>
        )}
        {event && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {event.team?.teamId && (
                    <Typography variant="h6">
                        {isLandscapeOrDesktop
                            ? `${event.team.teamMarket || ''} ${event.team.teamName || ''}`
                            : `${event.team.teamAbbr || ''}`}
                        {' - '}
                    </Typography>
                )}
                {event.type === 'Other' ? (
                    <Typography variant="h6" color="text.secondary">
                        {event.name.split('-')[0]}
                    </Typography>
                ) : (
                    <Typography variant="h6" color="text.secondary">
                        {event.type}
                    </Typography>
                )}
            </Box>
        )}
        {isLoading && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="h6">
                    <Skeleton width={100} height={30} />
                </Typography>
            </Box>
        )}
    </>
)

const DialogHeader = ({
    isGame,
    isCamp,
    event,
    isLandscapeOrDesktop,
    isFutureEvent,
    isLoading,
    eventDate,
}: DialogHeaderProps) => (
    <DialogTitle
        sx={{
            display: 'flex',
            gap: 5,
            justifyContent: 'center',
            alignItems: 'center',
            paddingX: 1,
            paddingY: 1,
        }}
    >
        {!isGame && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    {isLoading && <Skeleton width={50} height={10} />}
                    {eventDate && !isLoading && (
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                            {formatDateString(eventDate, 'MMMM D', 'utc')}
                        </Typography>
                    )}
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        {isCamp && event?.scoutingEventName ? (
                            <ScoutingEventName scoutingEventName={event.scoutingEventName} />
                        ) : (
                            <SingleTeamHeader
                                event={event}
                                isLandscapeOrDesktop={isLandscapeOrDesktop}
                                isLoading={isLoading}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        )}
        {isGame && (
            <GameDetailsHeader
                event={
                    event
                        ? {
                              awayTeamAbbr: event.awayTeamAbbr,
                              awayTeamLogoUrl: event.awayTeamLogoUrl,
                              awayTeamMarket: event.awayTeamMarket,
                              awayTeamName: event.awayTeamName,
                              awayTeamScore: event.awayTeamScore,
                              gameTimeUtc: event.gameTimeUtc,
                              homeTeamAbbr: event.homeTeamAbbr,
                              homeTeamLogoUrl: event.homeTeamLogoUrl,
                              homeTeamMarket: event.homeTeamMarket,
                              homeTeamName: event.homeTeamName,
                              homeTeamScore: event.homeTeamScore,
                              venue: event.venue,
                              date: event.date,
                              status: event.gameStatus,
                          }
                        : undefined
                }
                isLandscapeOrDesktop={isLandscapeOrDesktop}
                isFutureEvent={isFutureEvent}
                isLoading={isLoading}
            />
        )}
    </DialogTitle>
)

type PlayerMappingResp = DTO.GameBoardPlayer & {
    assignmentId: string
    gameId: string
    boxscore: DTO.GameBoxscore | undefined
    state: DTO.IncompleteReport['state']
}
type PlayersByTeamResp = Record<'homeTeamPlayers' | 'awayTeamPlayers', PlayerMappingResp[]>
type EventContentProps = {
    playersByTeam: PlayersByTeamResp
    isGame: boolean
    isPlayersLoading: boolean
    setPlayer: (player: Partial<DTO.BoardPlayer> | null) => void
}

const FutureEventTable = ({
    players,
    isPlayersLoading,
    showTiers,
    draftYear,
    setSelectedIntelNote,
}: {
    players: PlayerMappingResp[]
    isPlayersLoading: boolean
    showTiers: boolean
    draftYear: number
    setSelectedIntelNote: (note: DTO.IntelCoverageNote | null) => void
}) => (
    <Grid container spacing={2}>
        <Table<DTO.GameBoardPlayer>
            config={preEventTableConfig(showTiers, draftYear, setSelectedIntelNote)}
            rows={players}
            getRowKey={(p) => p.urlSlug}
            isLoading={isPlayersLoading}
        />
    </Grid>
)

const FutureEventContent = ({
    playersByTeam,
    isGame,
    isPlayersLoading,
    event,
    showTiers,
    draftYear,
    setSelectedIntelNote,
}: Pick<EventContentProps, 'playersByTeam' | 'isGame' | 'isPlayersLoading'> & {
    event: DTO.AssignmentCalendarExtendedProps | undefined
    showTiers: boolean
    draftYear: number
    setSelectedIntelNote: (note: DTO.IntelCoverageNote | null) => void
}) => (
    <DialogContent
        sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: { xs: 2, md: 5 },
            paddingLeft: 2,
            paddingRight: 1,
            paddingY: 1,
        }}
        dividers
    >
        <Grid container>
            <Box marginBottom={2}>
                <TeamTableTitle
                    teamLogoUrl={event?.awayTeamLogoUrl || null}
                    teamMarket={event?.awayTeamMarket || null}
                    teamName={event?.awayTeamName || null}
                    marginLeft="-8px"
                    isLoading={isPlayersLoading}
                />
            </Box>
            <FutureEventTable
                players={playersByTeam.awayTeamPlayers}
                isPlayersLoading={isPlayersLoading}
                showTiers={showTiers}
                draftYear={draftYear}
                setSelectedIntelNote={setSelectedIntelNote}
            />
        </Grid>
        {isGame && (
            <Grid container>
                <Box marginBottom={2}>
                    <TeamTableTitle
                        teamLogoUrl={event?.homeTeamLogoUrl || null}
                        teamMarket={event?.homeTeamMarket || null}
                        teamName={event?.homeTeamName || null}
                        marginLeft="-8px"
                        isLoading={isPlayersLoading}
                    />
                </Box>
                <FutureEventTable
                    players={playersByTeam.homeTeamPlayers}
                    isPlayersLoading={isPlayersLoading}
                    showTiers={showTiers}
                    draftYear={draftYear}
                    setSelectedIntelNote={setSelectedIntelNote}
                />
            </Grid>
        )}
    </DialogContent>
)

const PastEventTable = ({
    isGame,
    players,
    showRowHighlighting,
    setPlayer,
    isPlayersLoading,
    setOpenLOCDialog,
    setOpenSkillDialog,
    showControls,
    showTiers,
}: {
    isGame: boolean
    players: PlayerMappingResp[]
    showRowHighlighting: boolean
    isPlayersLoading: boolean
    setPlayer: (player: Partial<DTO.BoardPlayer>) => void
    setOpenLOCDialog: (id: string) => void
    setOpenSkillDialog: (id: string) => void
    showControls: boolean
    showTiers: boolean
}) => (
    <Table<BoardPlayerWithBoxScore>
        config={postEventTableConfig(
            isGame,
            showRowHighlighting,
            setPlayer,
            setOpenLOCDialog,
            setOpenSkillDialog,
            showControls,
            showTiers
        )}
        rows={players.map((p) => ({
            ...p,
            ...p.boxscore,
        }))}
        getRowKey={(p) => p.playerId}
        formatRow={(p) =>
            !showRowHighlighting
                ? undefined
                : p.state === 'Complete'
                ? { '.MuiTableCell-root, .boxscore-min': { backgroundColor: '#e8f5e9' } }
                : p.state === 'Incomplete - Inactive' || p.state === 'Incomplete - Active'
                ? { '.MuiTableCell-root, .boxscore-min': { backgroundColor: '#ffebee' } }
                : p.state === 'Exempt'
                ? { '.MuiTableCell-root, .boxscore-min': { backgroundColor: '#f5f5f5' } }
                : undefined
        }
        hover={false}
        isLoading={isPlayersLoading}
    />
)

const PastEventContent = ({
    playersByTeam,
    isGame,
    event,
    showRowHighlighting,
    isPlayersLoading,
    setPlayer,
    setOpenLOCDialog,
    setOpenSkillDialog,
    showControls,
    showTiers,
}: EventContentProps & {
    event: DTO.AssignmentCalendarExtendedProps | undefined
    showRowHighlighting: boolean
    setOpenLOCDialog: (id: string) => void
    setOpenSkillDialog: (id: string) => void
    showControls: boolean
    showTiers: boolean
}) => (
    <DialogContent
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            paddingLeft: 0,
            paddingRight: 1,
            paddingY: 1,
        }}
        dividers
    >
        <Grid container>
            {isGame && (
                <TeamTableTitle
                    teamLogoUrl={event?.awayTeamLogoUrl || null}
                    teamMarket={event?.awayTeamMarket || null}
                    teamName={event?.awayTeamName || null}
                    isLoading={isPlayersLoading}
                />
            )}
            <PastEventTable
                isGame={isGame}
                players={playersByTeam.awayTeamPlayers}
                showRowHighlighting={showRowHighlighting}
                setPlayer={setPlayer}
                isPlayersLoading={isPlayersLoading}
                setOpenLOCDialog={setOpenLOCDialog}
                setOpenSkillDialog={setOpenSkillDialog}
                showControls={showControls}
                showTiers={showTiers}
            />
        </Grid>
        {!!playersByTeam.homeTeamPlayers.length && (
            <Grid container>
                <TeamTableTitle
                    teamLogoUrl={event?.homeTeamLogoUrl || null}
                    teamMarket={event?.homeTeamMarket || null}
                    teamName={event?.homeTeamName || null}
                    isLoading={isPlayersLoading}
                />
                <PastEventTable
                    isGame={isGame}
                    players={playersByTeam.homeTeamPlayers}
                    showRowHighlighting={showRowHighlighting}
                    setPlayer={setPlayer}
                    isPlayersLoading={isPlayersLoading}
                    setOpenLOCDialog={setOpenLOCDialog}
                    setOpenSkillDialog={setOpenSkillDialog}
                    showControls={showControls}
                    showTiers={showTiers}
                />
            </Grid>
        )}
    </DialogContent>
)

type CalendarEventDialogProps = {
    open: boolean
    setOpen: (open: boolean) => void
    event: DTO.AssignmentCalendarExtendedProps | undefined
    filterParams: ScoutAssignmentQueryParams
    widget?: boolean
    showControls?: boolean
}

const validationSchema = yup.object().shape({
    scouts: yup.array().ensure().min(0),
    type: yup.string().required(),
})

const CalendarEventDialog = ({
    open,
    setOpen,
    event: e,
    filterParams,
    widget = false,
    showControls = true,
}: CalendarEventDialogProps): JSX.Element | null => {
    const [isEditingScouts, setIsEditingScouts] = useState(false)
    const [randomString, setRandomString] = useState(uuidv4())
    const { draftYear } = useConstantsContext()
    const { mutate: submit } = useAddAssignment({ params: filterParams })
    const { mutate: edit } = useEditScouts({ params: filterParams })
    const { data: session } = useSession()
    const [unrequiredPlayer, setUnrequiredPlayer] = useState<Partial<DTO.BoardPlayer> | null>(null)
    const { isLandscape, isDesktop } = useBreakPoints()
    const [openLOCDialog, setOpenLOCDialog] = useState<string | undefined>(undefined)
    const [openSkillDialog, setOpenSkillDialog] = useState<string | undefined>(undefined)
    const [selectedNote, setSelectedIntelNote] = useState<DTO.IntelCoverageNote | null>(null)
    const canViewAssignments = session?.roles.featurePermissions['assignment-schedule']
    const isAmateurScout = session?.roles.contentPermissions['amateur-assignments']
    const isProScout = session?.roles.contentPermissions['pro-assignments']
    const isAssignmentScheduleManagement = !!session?.roles.featurePermissions['assignment-schedule-management']
    const isCoverageManager = !!session?.roles.featurePermissions['coverage-management']
    const showScoutLevelDropdown = isAmateurScout && isProScout && isAssignmentScheduleManagement
    const eventType = (e?.gameId ? 'Game' : e?.type) as Enum.ScoutingReportSettingType
    const eventDate = e?.gameTimeUtc || e?.date || undefined
    const eventLevel = e?.level as Enum.BoardLevels
    const isCamp = eventType === 'Event/Camp'
    const isGame = eventType === 'Game'
    const isFutureEvent = eventDate ? isSameOrAfterToday(eventDate) : false
    const { data: boxscores, isInitialLoading: isLoadingBoxscores } = useGameBoxScores(
        [{ gameId: e?.gameId ? e.gameId : '' }],
        !!e?.gameId
    )

    const { data: eventDetailsArr, isInitialLoading: isLoadingEventDetails } = useQueryMappedAssignmentsForCalendar(
        {
            assignmentIds: e?.assignmentId ? [e.assignmentId] : undefined,
            gameIds: isGame ? [e?.gameId || ''] : undefined,
            includeDetailFields: true,
            viewing: filterParams.viewing,
        },
        {
            enabled: isGame ? !!e?.gameId : !!e?.assignmentId,
        }
    )

    const eventDetails = eventDetailsArr?.[0]
    const event = useMemo(() => {
        if (eventDetails) {
            return { ...e, ...eventDetails.extendedProps } as DTO.AssignmentCalendarExtendedProps
        }
        return e
    }, [e, eventDetails])

    let assignmentLevel: Enum.BoardLevels = isAmateurScout && !isProScout ? 'Amateur' : 'Pro'
    if (isAssignmentScheduleManagement && event) assignmentLevel = event.level

    const {
        startDate: incompleteReportStartDate,
        endDate: incompleteReportEndDate,
        scoutId: incompleteReportScoutId,
    } = getIncompleteAssignmentsQueryParams(session)
    const { data: incompleteReports } = useQueryIncompleteReports(
        {
            gameId: event?.gameId || undefined,
            scoutId: incompleteReportScoutId,
            assignmentId: event?.assignmentId || undefined,
            startDate: incompleteReportStartDate,
            endDate: incompleteReportEndDate,
        },
        { enabled: event && !isFutureEvent && (!!isAmateurScout || !!isProScout) }
    )

    const isCurrentUserAssigned = event?.scoutAssignments.some((sa) => sa.entityId === session?.entityId)

    const isExemptFromRequiredReports = !!session?.roles.featurePermissions['required-report-exemption']
    const showRowHighlighting =
        (isCurrentUserAssigned && !isExemptFromRequiredReports) || isAssignmentScheduleManagement

    const { data: boardPlayers, isLoading: isBoardPlayersLoading } = useQueryBoardPlayers({
        params: {
            gameId: event?.gameId ? event.gameId : undefined,
            assignmentId:
                filterParams.viewing === 'games'
                    ? undefined
                    : event?.assignmentId && event.assignmentId !== ''
                    ? event.assignmentId
                    : event?.scoutAssignments.length === 1 ||
                      new Set(event?.scoutAssignments.map((d) => d.boardId)).size === 1
                    ? event?.scoutAssignments[0].assignmentId || undefined
                    : undefined,
            scoutId:
                filterParams.viewing === 'games'
                    ? undefined
                    : event?.scoutId && event.scoutId !== ''
                    ? event.scoutId
                    : event?.scoutAssignments.length === 1
                    ? event.scoutAssignments[0].entityId
                    : undefined,
            boardId: filterParams.viewing === 'games' ? filterParams.boardId : undefined,
        },
    })
    const initialScouts: string[] = useMemo(
        () =>
            isAssignmentScheduleManagement && event
                ? event.scoutAssignments.map((s) => s.entityId)
                : [session?.entityId || ''],
        [event, isAssignmentScheduleManagement, session?.entityId]
    )

    const loggedInUserExistingScoutAssignment = useMemo(
        () => event?.scoutAssignments.find((s) => s.entityId === session?.entityId),
        [event?.scoutAssignments, session?.entityId]
    )

    const assignedScouts = (assignments: DTO.AssignedScout[]) =>
        assignments.map((s, i) => [
            i > 0 && (
                <Typography key={s.urlSlug} marginRight="5px">
                    {', '}
                </Typography>
            ),
            <Box key={s.entityId} sx={{ display: 'flex', alignItems: 'baseline', whiteSpace: 'nowrap' }}>
                <Link
                    sx={{ fontStyle: s.assignmentStatus === 'PENDING' ? 'italic' : 'normal' }}
                    href={s.urlSlug ? `/users/${s.urlSlug}/feed` : ''}
                    {...{ target: '_blank', rel: 'noopener noreferrer' }}
                >
                    {s.name}
                </Link>
                {s.assignmentStatus === 'PENDING' && (
                    <Typography
                        sx={{ marginLeft: '2px', fontStyle: 'italic', fontSize: '14px' }}
                        color="text.secondary"
                    >
                        {' '}
                        (pending approval)
                    </Typography>
                )}
            </Box>,
        ])

    const boxScoresByPlayer = useMemo(
        () =>
            boxscores?.reduce(
                (acc: Record<string, DTO.GameBoxscore>, boxscore) => ({ ...acc, [boxscore.playerId]: boxscore }),
                {}
            ),
        [boxscores]
    )

    const incompleteReportsByPlayer = useMemo(
        () =>
            incompleteReports
                ?.filter((d) => d.scoutId === session?.entityId || isAssignmentScheduleManagement)
                ?.reduce((acc: Record<string, { state: DTO.IncompleteReport['state'] }>, ir) => {
                    acc[ir.playerId] = { state: ir.state }
                    return acc
                }, {}),
        [incompleteReports, isAssignmentScheduleManagement, session?.entityId]
    )
    const playersByTeam = useMemo(() => {
        if (!boardPlayers || boardPlayers.length === 0 || isLoadingEventDetails)
            return { homeTeamPlayers: [], awayTeamPlayers: [] }
        const res = boardPlayers.reduce(
            (acc: PlayersByTeamResp, player) => {
                if (
                    event &&
                    (player.teamId === event.homeTeamId || player.gLeagueTeamId === event.homeTeamId) &&
                    event.homeTeamId
                )
                    acc.homeTeamPlayers.push({
                        ...player,
                        assignmentId: event.assignmentId,
                        gameId: event.gameId as string,
                        boxscore: boxScoresByPlayer ? boxScoresByPlayer[player.playerId] : undefined,
                        state: incompleteReportsByPlayer?.[player.playerId]
                            ? incompleteReportsByPlayer[player.playerId].state
                            : 'Not Required',
                    })
                else if (event)
                    acc.awayTeamPlayers.push({
                        ...player,
                        assignmentId: event.assignmentId,
                        gameId: event.gameId as string,
                        boxscore: boxScoresByPlayer ? boxScoresByPlayer[player.playerId] : undefined,
                        state: incompleteReportsByPlayer?.[player.playerId]
                            ? incompleteReportsByPlayer[player.playerId].state
                            : 'Not Required',
                    })

                return acc
            },
            { homeTeamPlayers: [], awayTeamPlayers: [] }
        )

        if (!isFutureEvent) {
            const customSort = (a: PlayerMappingResp, b: PlayerMappingResp) =>
                (b.boxscore?.gp ? 1 : 0) - (a.boxscore?.gp ? 1 : 0) ||
                (b.boxscore ? 1 : 0) - (a.boxscore ? 1 : 0) ||
                (b.boxscore?.gs ? 1 : 0) - (a.boxscore?.gs ? 1 : 0) ||
                (b.boxscore?.min || 0) - (a.boxscore?.min || 0) ||
                (b.boxscore?.dnpReason === null ? 1 : -1) - (a.boxscore?.dnpReason === null ? 1 : -1) ||
                (a.boxscore?.dnpReason || '').localeCompare(b.boxscore?.dnpReason || '')

            const sortedHomeTeamPlayers = res.homeTeamPlayers.sort((a, b) => customSort(a, b))
            const sortedAwayTeamPlayers = res.awayTeamPlayers.sort((a, b) => customSort(a, b))
            return { homeTeamPlayers: sortedHomeTeamPlayers, awayTeamPlayers: sortedAwayTeamPlayers }
        }
        return res
    }, [boardPlayers, isFutureEvent, event, boxScoresByPlayer, incompleteReportsByPlayer, isLoadingEventDetails])
    useEffect(() => {
        setRandomString(uuidv4())
    }, [open])

    const toastContext = useToastContext()

    const formik = useFormik<Omit<DTO.AddAssignment, 'scouts'> & { scouts: string[] }>({
        validationSchema,
        enableReinitialize: true,
        initialValues: {
            assignmentId: randomString,
            gameId: event?.gameId || null,
            players: event?.players || [],
            // If game is selected from calendar date will be present
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            date: event?.gameTimeUtc || '',
            level: assignmentLevel,
            scouts: initialScouts,
            type: eventType,
            scoutingEventId: null,
            info: null,
            boardId: event?.boardId || null,
            team: null,
        },
        onSubmit: (values, { setSubmitting }) => {
            const submitValues: Omit<DTO.AddAssignment, 'scouts'> & { scouts: string[] } = {
                assignmentId: values.assignmentId,
                date: formatDateString(values.date, 'YYYY-MM-DD', 'utc'),
                gameId: values.type === 'Game' ? values.gameId : null,
                scouts: values.scouts,
                players: values.players,
                boardId: values.boardId,
                team: null,
                scoutingEventId: null,
                info: null,
                type: values.type,
                level: values.level,
            }
            // assignmentScheduleManagement and existing scoutAssignments
            if (isAssignmentScheduleManagement && !!event?.scoutAssignments.length) {
                const existingAssignmentId = event.scoutAssignments[0].assignmentId
                const addedScoutAssignments: DTO.AssignedScout[] = submitValues.scouts.map((entityId) => ({
                    entityId,
                    assignmentId:
                        event.scoutAssignments.find((sa) => sa.entityId === entityId)?.assignmentId ||
                        existingAssignmentId,
                    assignmentStatus: 'APPROVED',
                }))
                const removedScoutIds = initialScouts.filter((scoutId) => !submitValues.scouts.includes(scoutId))
                const removedScoutAssignments: DTO.AssignedScout[] = removedScoutIds.length
                    ? removedScoutIds.map((entityId) => ({
                          entityId,
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
                          assignmentId: event.scoutAssignments.find((sa) => sa.entityId === entityId)?.assignmentId!,
                          assignmentStatus: 'DELETED',
                      }))
                    : []
                edit(
                    {
                        scouts: [...addedScoutAssignments, ...removedScoutAssignments],
                        boardId: submitValues.boardId || undefined,
                    },
                    {
                        onSuccess: () => {
                            toastContext?.addToast({
                                severity: 'success',
                                message: `Assignment Updated`,
                            })
                            formik.resetForm()
                            setOpen(false)
                        },
                        onError: (error) => handleError(error, toastContext, setSubmitting),
                    }
                )
                // assignmentScheduleManagement and  no existing scoutAssignments
            } else if (isAssignmentScheduleManagement) {
                submit(
                    [
                        {
                            ...submitValues,
                            scouts: submitValues.scouts.map((entityId) => ({
                                entityId,
                                assignmentId: submitValues.assignmentId,
                                assignmentStatus: 'APPROVED',
                            })),
                        },
                    ],
                    {
                        onSuccess: () => {
                            toastContext?.addToast({
                                severity: 'success',
                                message: 'Assignment Updated',
                            })
                            formik.resetForm()
                            setOpen(false)
                        },
                        onError: (error) => handleError(error, toastContext, setSubmitting),
                    }
                )
                // pro/am scout is assigned to game
            } else if (loggedInUserExistingScoutAssignment) {
                edit(
                    {
                        scouts: [
                            {
                                assignmentId: loggedInUserExistingScoutAssignment.assignmentId,
                                entityId: loggedInUserExistingScoutAssignment.entityId,
                                assignmentStatus: 'DELETED',
                            },
                        ],
                        boardId: submitValues.boardId || undefined,
                    },
                    {
                        onSuccess: () => {
                            toastContext?.addToast({
                                severity: 'success',
                                message: `Assignment Removed`,
                            })
                            formik.resetForm()
                            setOpen(false)
                        },
                        onError: (error) => handleError(error, toastContext, setSubmitting),
                    }
                )
                // pro/am scout is not assigned to game
            } else {
                submit(
                    [
                        {
                            ...submitValues,
                            scouts: submitValues.scouts.map((entityId) => ({
                                entityId,
                                assignmentId: submitValues.assignmentId,
                                assignmentStatus: 'PENDING',
                            })),
                        },
                    ],
                    {
                        onSuccess: () => {
                            toastContext?.addToast({
                                severity: 'success',
                                message: 'Assignment Requested',
                            })
                            formik.resetForm()
                            setOpen(false)
                        },
                        onError: (error) => handleError(error, toastContext, setSubmitting),
                    }
                )
            }
        },
    })
    const isRosterDataAvailable =
        playersByTeam.awayTeamPlayers.length || playersByTeam.homeTeamPlayers.length || isBoardPlayersLoading

    const notePlayer = useMemo(
        () => boardPlayers?.find((p) => p.playerId === selectedNote?.playerId),
        [boardPlayers, selectedNote?.playerId]
    )

    return (
        <Dialog
            maxWidth={isGame && !isFutureEvent ? 'lg' : 'md'}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        minWidth: { xs: 300, sm: 500 },
                    },
                },
            }}
            scroll="paper"
            open={open}
            onClose={() => setOpen(false)}
        >
            <Box
                component="form"
                noValidate
                onSubmit={formik.handleSubmit}
                sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
            >
                <DialogHeader
                    event={event}
                    isCamp={isCamp}
                    isGame={isGame}
                    isLandscapeOrDesktop={isLandscape || isDesktop}
                    isFutureEvent={isFutureEvent}
                    isLoading={isLoadingEventDetails}
                    eventDate={eventDate}
                />
                {!isRosterDataAvailable && !isLoadingEventDetails && e && (
                    <DialogContent
                        dividers
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: 150,
                        }}
                    >
                        <Typography variant="h6" color="text.secondary">
                            No roster data available
                        </Typography>
                    </DialogContent>
                )}
                {(isRosterDataAvailable || isLoadingEventDetails || !e) && (
                    <>
                        {isFutureEvent ? (
                            <FutureEventContent
                                playersByTeam={playersByTeam}
                                isGame={isGame}
                                isPlayersLoading={
                                    isLoadingBoxscores || isBoardPlayersLoading || isLoadingEventDetails || !e
                                }
                                event={event}
                                showTiers={!!canViewAssignments}
                                draftYear={draftYear}
                                setSelectedIntelNote={setSelectedIntelNote}
                            />
                        ) : (
                            <PastEventContent
                                playersByTeam={playersByTeam}
                                isGame={isGame}
                                event={event}
                                showRowHighlighting={showRowHighlighting}
                                setPlayer={setUnrequiredPlayer}
                                isPlayersLoading={
                                    isLoadingBoxscores || isBoardPlayersLoading || isLoadingEventDetails || !e
                                }
                                setOpenLOCDialog={setOpenLOCDialog}
                                setOpenSkillDialog={setOpenSkillDialog}
                                showControls={showControls}
                                showTiers={!!canViewAssignments}
                            />
                        )}
                    </>
                )}
                <DialogActions
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: canViewAssignments ? 'space-between' : 'flex-end',
                        alignItems: { xs: 'stretch', sm: 'center' },
                        minHeight: 56,
                        gap: { xs: 1, md: 0 },
                    }}
                >
                    {canViewAssignments && (
                        <Box
                            sx={{
                                justifyContent: 'flex-start',
                                width: { xs: '100%', md: 'initial' },
                                paddingLeft: { xs: 0, md: 1 },
                            }}
                        >
                            {isEditingScouts && !widget ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'column', sm: 'row' },
                                        alignItems: 'center',
                                        minWidth: { xs: 0, sm: 422 },
                                        gap: { xs: 1, sm: 0 },
                                    }}
                                >
                                    {showScoutLevelDropdown && (
                                        <Box sx={{ marginRight: 1 }}>
                                            <SelectLevel onChange={formik.handleChange} value={formik.values.level} />
                                        </Box>
                                    )}
                                    <FormControl fullWidth sx={{ marginRight: { xs: 0, sm: 1 } }}>
                                        <SelectScout
                                            value={formik.values.scouts}
                                            onChange={(ev) =>
                                                formik.setFieldValue('scouts', ev.target.value as string[])
                                            }
                                            level={formik.values.level}
                                        />
                                    </FormControl>
                                    {e?.league ? (
                                        <SelectBoard
                                            onChange={formik.handleChange}
                                            value={formik.values.boardId}
                                            league={e.league}
                                        />
                                    ) : (
                                        <SelectBoard
                                            onChange={formik.handleChange}
                                            value={formik.values.boardId}
                                            level={formik.values.level}
                                        />
                                    )}
                                    <Box sx={{ marginLeft: 1 }}>
                                        <IconButton
                                            onClick={() => setIsEditingScouts(false)}
                                            size="small"
                                            sx={{ alignSelf: 'baseline' }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        <Typography sx={{ marginRight: '4px' }}>
                                            {isFutureEvent ? 'Attending:' : 'Attended:'}
                                        </Typography>{' '}
                                        {event?.scoutAssignments.length ? (
                                            <>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        marginRight: 1,
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    {assignedScouts(event.scoutAssignments)}
                                                </Box>
                                                {event.boardName && (
                                                    <Box>
                                                        Board:&nbsp;
                                                        <Link href={`/boards/${event.boardId}`} target="blank">
                                                            {event.boardName}
                                                        </Link>
                                                    </Box>
                                                )}
                                            </>
                                        ) : isLoadingEventDetails ? (
                                            <Skeleton width={100} height={20} sx={{ marginLeft: 1 }} />
                                        ) : (
                                            <Typography
                                                sx={{ display: 'inline-block' }}
                                                color="text.primary"
                                                fontWeight="medium"
                                            >
                                                No Scouts Assigned
                                            </Typography>
                                        )}
                                    </Box>
                                    {isAssignmentScheduleManagement && !widget && (
                                        <Box sx={{ marginLeft: 1 }}>
                                            <IconButton
                                                onClick={() => setIsEditingScouts(true)}
                                                color="primary"
                                                size="small"
                                                sx={{ alignSelf: 'baseline' }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    )}
                    <Divider sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }} />
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            marginLeft: '0px !important',
                            width: { xs: '100%', md: 'initial' },
                        }}
                    >
                        <Button
                            sx={{ flex: { xs: 1, lg: 'initial' } }}
                            variant="text"
                            onClick={() => {
                                setOpen(false)
                                formik.resetForm()
                            }}
                            disabled={formik.isSubmitting}
                        >
                            Close
                        </Button>
                        {!widget && (
                            <>
                                {isAssignmentScheduleManagement ? (
                                    <LoadingButton
                                        sx={{
                                            flex: { xs: 1, lg: 'initial' },
                                            whiteSpace: 'nowrap',
                                            display: isEditingScouts ? 'flex' : 'none',
                                            minWidth: 157,
                                        }}
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        loading={formik.isSubmitting}
                                        disabled={formik.isSubmitting || !formik.isValid || !isEditingScouts}
                                    >
                                        {isLandscape || isDesktop ? 'Update Assignment' : 'Update'}
                                    </LoadingButton>
                                ) : (
                                    isAmateurScout &&
                                    isFutureEvent &&
                                    loggedInUserExistingScoutAssignment?.assignmentStatus !== 'APPROVED' && (
                                        <LoadingButton
                                            sx={{ flex: { xs: 1, lg: 'initial', whiteSpace: 'nowrap' }, minWidth: 157 }}
                                            color={loggedInUserExistingScoutAssignment ? 'error' : 'primary'}
                                            variant="contained"
                                            type="submit"
                                            loading={formik.isSubmitting}
                                            disabled={formik.isSubmitting || !formik.isValid}
                                        >
                                            {loggedInUserExistingScoutAssignment ? 'Remove' : 'Add'}{' '}
                                            {(isLandscape || isDesktop) && ' Assignment'}
                                        </LoadingButton>
                                    )
                                )}
                            </>
                        )}
                    </Box>
                </DialogActions>
            </Box>
            {event && (
                <RequiredReportModal
                    open={!!unrequiredPlayer}
                    setOpen={setUnrequiredPlayer}
                    player={unrequiredPlayer as DTO.BoardPlayer}
                    onClose={() => setUnrequiredPlayer(null)}
                    assignment={event}
                    filterParams={filterParams}
                    incompleteReportParams={{
                        gameId: event.gameId || undefined,
                        scoutId: event.scoutId || undefined,
                        assignmentId: event.assignmentId || undefined,
                    }}
                />
            )}

            {!!openLOCDialog && event && (
                <LOCDialog
                    open={!!openLOCDialog}
                    setOpen={setOpenLOCDialog}
                    name={boardPlayers?.find((bp) => bp.playerId === openLOCDialog)?.fullName || ''}
                    playerId={openLOCDialog}
                    isNBAPlayer={!!event.players?.find((bp) => bp.entityId === openLOCDialog)?.isNBAPlayer}
                />
            )}
            {!!openSkillDialog && event && (
                <SkillDialog
                    open={!!openSkillDialog}
                    setOpen={setOpenSkillDialog}
                    name={boardPlayers?.find((bp) => bp.playerId === openSkillDialog)?.fullName || ''}
                    playerId={openSkillDialog}
                    boardLevel={eventLevel}
                />
            )}
            {!!selectedNote && event && (
                <IntelCoverageNoteModal
                    initVal={selectedNote}
                    setInitVal={setSelectedIntelNote}
                    playerName={notePlayer?.fullName || ''}
                    isManager={isCoverageManager}
                    authorName={notePlayer?.intelCoverageNoteAuthor || ''}
                />
            )}
        </Dialog>
    )
}

export default CalendarEventDialog
