import type { HandleSubmit } from '@/lib/types/formDialogTypes'
import type { SetStateAction } from 'react'
import React from 'react'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Grid from '@mui/material/Grid'
import { useSession } from 'next-auth/react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { isSubmitButtonDisabled } from '../form/formUtils'
import LOC from '../form/LOC'
import { useCreateLOCsForBoardPlayer } from '@/lib/hooks'
import { isNumber } from '@/lib/utils/math'
import useToastContext from '@/lib/hooks/useToastContext'

type LOCDialogProps = {
    open: boolean
    setOpen: (open: SetStateAction<string | undefined>) => void
    name: string
    playerId: string
    isNBAPlayer: boolean
    selectedColumns?: Enum.BoardColumns[] | undefined
    boardId?: string
}

const LOCDialog = ({
    open,
    setOpen,
    name,
    playerId,
    isNBAPlayer,
    boardId,
    selectedColumns,
}: LOCDialogProps): JSX.Element => {
    const toastContext = useToastContext()
    const { data: session } = useSession()
    const createLOCs = useCreateLOCsForBoardPlayer(boardId || '', selectedColumns)
    const handleCreateLOCs = (values: DTO.LOC, { resetForm }: HandleSubmit<DTO.LOC>) =>
        createLOCs.mutateAsync(values, {
            onSuccess: () => {
                toastContext?.addToast({ severity: 'success', message: `Created LOCs for ${name}` })
                setOpen(undefined)
                resetForm()
            },
            onError: (error) => {
                toastContext?.addToast({ severity: 'error', message: error.message })
            },
        })

    const handleSubmit = (values: DTO.LOC, { resetForm }: HandleSubmit<DTO.LOC>) =>
        handleCreateLOCs(values, { resetForm })

    const validationSchema = yup.object({
        playerId: yup.string().trim().required('Player is required'),
        scoutEntityId: yup.string().trim().required('Scout is required'),
        locNow: isNBAPlayer ? yup.number().min(0).max(8).required() : yup.number().min(0).max(8).nullable(),
        locHigh: yup
            .number()
            .when('locBullseye', (locBullseye: number | null, schema: yup.NumberSchema<number | undefined | null>) =>
                schema
                    .min(
                        isNumber(locBullseye) ? locBullseye : 0,
                        'LOC High must be greater than or equal to LOC Bullseye'
                    )
                    .max(8)
                    .required()
            ),
        locBullseye: yup.number().min(0).max(8).required(),
        locLow: yup
            .number()
            .when('locBullseye', (locBullseye: number | null, schema: yup.NumberSchema<number | undefined | null>) =>
                schema
                    .min(0)
                    .max(isNumber(locBullseye) ? locBullseye : 8, 'LOC Low must be less than or equal to LOC Bullseye')
                    .required()
            ),
    })

    const formik = useFormik<DTO.LOC>({
        initialValues: {
            locHigh: null,
            locLow: null,
            locBullseye: null,
            locNow: null,
            playerId,
            scoutEntityId: session?.entityId as string,
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            await handleSubmit(values, { resetForm })
        },
        enableReinitialize: true,
    })

    return (
        <Dialog scroll="paper" open={open} onClose={() => setOpen(undefined)}>
            <Box
                component="form"
                noValidate
                onSubmit={formik.handleSubmit}
                sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        gap: 0.7,
                        flexWrap: 'wrap',
                        paddingY: 1,
                    }}
                >
                    <Typography variant="h6" fontWeight="normal">
                        Add LOCs for
                    </Typography>
                    {name}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid xs={12} item container rowSpacing={2} columnSpacing={2}>
                        <Grid xs={6} sm={12} item>
                            <LOC
                                name="locNow"
                                label="Now"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('locNow', val)}
                                required={isNBAPlayer}
                            />
                        </Grid>
                        <Grid xs={6} sm={12} item>
                            <LOC
                                name="locLow"
                                label="Low"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('locLow', val)}
                                required
                            />
                        </Grid>

                        <Grid xs={6} sm={12} item>
                            <LOC
                                name="locBullseye"
                                label="Bull"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('locBullseye', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={6} sm={12} item>
                            <LOC
                                name="locHigh"
                                label="High"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('locHigh', val)}
                                required
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        onClick={() => {
                            setOpen(undefined)
                            formik.resetForm()
                        }}
                        disabled={formik.isSubmitting}
                        size="large"
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        size="large"
                        loading={formik.isSubmitting}
                        disabled={isSubmitButtonDisabled({
                            isSubmitting: formik.isSubmitting,
                            dirty: formik.dirty,
                            isValid: formik.isValid,
                            submitCount: formik.submitCount,
                        })}
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default LOCDialog
